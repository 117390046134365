import '../common.js';
import '../../css/index.css';
	$(document).ready(function() {
// $("#cssmenu").menumaker({
// title: "MAIN MENU",
// format: "multitoggle"
// });
 //    var wow = new WOW(
	// 	{
	// 		boxClass: 'wow', // animated element css class (default is wow)
	// 		animateClass: 'animated', // animation css class (default is animated)
	// 		offset: 0, // distance to the element when triggering the animation (default is 0)
	// 		mobile: false // trigger animations on mobile devices (true is default)
	// 	}
	// );
		//wow.init(); 
		
	$('#main-slideshow').carousel();
			
			  var owl = $(".brands");
		      	owl.owlCarousel({
				  items : 6, //10 items above 1000px browser width
				  itemsDesktop : [1400,5], //5 items between 1400px and 1025px
				  itemsDesktopSmall : [1024,4], // 3 items betweem 1024px and 901px
				  itemsTablet: [900,3], //2 items between 900 and 481;
				  itemsMobile : [480,2], //1 item between 480 and 0;
				  rewindNav: false,
				  navigation : true // itemsMobile disabled - inherit from itemsTablet option
		      });

			});		